import Create from './create' 
import Edit from './edit' 
import List from './list' 
import Show from './show' 

export default {
    // create: Create,
    edit: Edit, 
    list: List,
    show: Show,
}