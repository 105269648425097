
import React from 'react';
import { TextInput, ReferenceInput, number, validate, minValue, NumberInput, Edit, ImageField, SimpleForm, SelectInput, RadioButtonGroupInput, required, ImageInput, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import DeleteButton from '../../control/button/delete-button';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import { locationTypes } from '../../util/variant'

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);
const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.option.edit')}: {record ? `${record.name}` : ''}</span>;
};

var types = [];
types.push({ id: 1, name: 'Oui/non' });
types.push({ id: 2, name: "Valeur" });

const validateNotSmallerThanOne = (value, allValues) => {
    if (value < 1) {
        return 'Doit ne pas être moins que 1';
    }
    return [];
};

const validatePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validateNotPositive = (value, allValues) => {
    if (value < 0) {
        return 'Doit être positif';
    }
    return [];
};

// const validateGreaterThanZero = (value, allValues) => {
//     if (value < 1) {
//         return 'Doit être au moins 1';
//     }
//     return [];
// };

const validatePositiveNumber = [required(), number(), validatePositive];
const validateNotNegativeNumber = [required(), number(), validateNotPositive];
const validateNotSmallerThanOneNumber = [required(), number(), validateNotSmallerThanOne];

const EditOption = ({ classes, permissions, ...props }) => {
    const translate = useTranslate();
    const uploadFileText = translate('ra.input.file.upload_single') + '(' + translate('max_size') + '100KB)';
    return (
        <Edit title={<Title />} {...props} actions={<EditActions />}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />}>

                <TextInput source="name_fr" label="Nom français" validate={required()} />
                <TextInput source="name_en" label="Nom anglais" validate={required()} />
                <ReferenceInput source="category_id" perPage={99999} allowEmpty defaultValue=""
                    reference="option_category" label="Catégorie">
                    <SelectInput optionText="name_fr" allowEmpty initialValue="" />
                </ReferenceInput>

                <SelectInput source="type" label="Type de valeur" validate={required()} choices={types} />
                <NumberInput source="price" label="Prix" initialValue={0} validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />


                <ImageInput source="photo" label="Icone" accept="image/*" maxSize='100000' placeholder={uploadFileText} validate={required()}>
                    <ImageField source="url" title="Fichier" />
                </ImageInput>

                <RadioButtonGroupInput source="is_locked" label="Verrouillé" choices={[
                    { id: "0", name: 'Non' },
                    { id: "1", name: 'Oui' },
                ]} initialValue={"0"} />

            </SimpleForm>
        </Edit>
    )
};

export default EditOption;