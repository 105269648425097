import React from 'react';

const Logo = props => (
  <svg version="1.1" id="Layer_1" 
  xmlns="http://www.w3.org/2000/svg" 
  x="0px" y="0px" width="167px" 
  height="50px" viewBox="0 0 167 50" enable-background="new 0 0 167 50" 
  >  <image id="image0" width="167" height="50" x="0" y="0"
  href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKcAAAAyCAYAAAA5vcscAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JQAAgIMAAPn/AACA6QAAdTAAAOpgAAA6mAAAF2+SX8VGAAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAA7EAAAOxAGVKw4bAAAZQ0lEQVR42u2debRkVX3vP799pqq68+2mu5kHG+UpiMgUEMQ5
CcpSg8ZFXOv5DGp8GHkR8Ul4oAJBCQZYiT6JCQ4PjRmIaKJGFBQFREYVbaAZBJqhm6G5fYeazrR/
74996lTVHbrvbRu6Wev+1tqrbp3aZ589fPdv3ueKqrJMy7QrktnZHVimZVqIlsG5TLssLYNzmXZZ
WgbnMu2ytAzOZdplaRmcy7TLkr/omr8eA/W639serG7DftML3zMNPFA8Reb5XQLQUVDbvWZk4fby
BDwf1IACuUAthhDIDNgE8gByoCbgCSQGhhLYY6bbzoYhaFkIFTYPwMo2pB7EBqwBSUEVPNyYxYBs
xeWmrMHK/0apAj7CzYh+ZZ56EFqoZjBZAZNBnkJUBc2gnTp2IaaYm+KZYvrZiAoYC2E6t20PeCYC
T91FzwAGrMDoJEQp2PmHgQFSA5PDbg7ZwW7GV0wuqfriwdntqAEURZFt3CK4ibDMD07P9re50GwI
DohWXI+fb9esdjqxwINVDgL5SM8YX4nK/ODUhZDxPJD0lAV/V1A5EeUHFBDdWbR4cKr3YZAziw4r
hgzLeVj5xlbugShwu3n2hIhCZg5DuRJksGi3inIqcE1f3RwIczA52CXspx1FCshWVlWozcJtMLuC
A+bC++85JwPMFGWr5B+Nht9D8vcgXLlzOlv0ZPE1w3VkdgDsCgAiCzPhl7l37CognVNfDfgtqFqw
sxZEcMvXrP0jGQf3iyz7wJy2Mg9GU0gzaFSeZ0254JiqIB4LgGv2+Fv9TSh4uVM9tilungtSMAba
ASTpwvOnQDDwaXzAcA5ir9zu7iqQKowbWLV9jSyBDW25HuOfgh34Yam0WCIy789RvWxO9dyH8QQG
5+IWBWa8o2l7h+P3Sg79FMpv59TtiH+7Mxa2l7Yi2hciFcfxgwTS6s7rugWCQootSOHhSOV1kIFy
IOjJqH5zu56XA2sMkw9n/OAqh4F3fWxpTSwenGkMxNci0V0QHAq5WytPz0H0b5mtZpsMTBVsOH97
Gn1m1kI3Mfaz/XUKw2dnY9J1xo3JLoFtK25DeTiQ7lSyYIKF95cAOnAJvbkWai7abnBGwJjHn7+v
wT99PwaeS3BmxaC8xpn4o9f2DHAc+DOEy/vqBxa2RJDNuyiHEOhrCfLuoqm9GEuzW8WA5M563xl6
5mxSA34DsgjyCGQbho2Km4MggZa3uGc8twNwfZJRZ+n3kgA5R2D9E5BeSSZrEU5GlghQBfb3+L8X
dIG5PbR4NmCLh6bxdWTJr5z+1emMfBJb6JadkuMWpzqr1CxU7YWYzmQBqWmRBJ+lHeJKBLkH/gyQ
L8x1FKfPmd/RyJhj68xnuBiQDIKmA+piFshTiPJuP9lWf3eAwbQ1Bq0KEoEZAJlVtHrpAiz1ov5J
WkSpGfRxy7mfa/9OQ1k8SxovPg2QNs+kGV7XhbasRvS9kH2lOztaVJ61kJa1YE5CSmDCUPpZqlmX
a/oGmm1o54BXNKVuxys14PeBVRi9h9x7JbH8FtGfgNa3Sw9QBCtvQmUtcBd4h4M+AfYG4OluPQ+C
GLzEcfOFuacg2v1d5UXk/qsR9iU3dWLvfkR/BNroB0RnrmQlMFa2BQ8Bmduk2t2sczwggKUK7EPX
gfcMsKU7hoxy7jv3ZByJ5fhue32yfy0qf4Ry9SLnElYYHr4xYUv9d3ObLR6cgz3zl8c/Ikl+SRYe
hinEgJrzibKvYKTLES1O5xLtAiw155MXTu1cQGmx/9TFhD0DscB9PfPjKSRmFWnlLFROQXWN671e
QBKcW7T9DEa/isgnEdvaKkAVB3RDFeFs6tGfoBxQrMt54H/S9d/WQf4Z8nOAp1HjmvWbEI9uBZzi
YXJI2Ys8+BtE3kUSFu4zH1IfjD6Jms+BfBpVCHxnzccGkNcQcBWe7QBxHWJfju3MiHXzmvr9g8pM
jdz/NZ68qMSX6Osx/Lh0ZYl0vcodHFou7XZdQdLTsMEngdXFxYswujhwGtflT13RWlT1bTS1SIqL
0irAU2me2d1cCurvhfp/gmQ4BbXwvlsLeSmu9sKaU9wEALEHu7X+htA2yudY4H6gTRFZUhA9kXp4
D3H0EXxd4yZaJtDCSWwUlN3Iw4+R+XeDvtQtgAfWmzvM0EI9OoJG5R4CzsEWwHSAzrvi1QyCvB/M
PaicgKjrn2mDJFsR77oB9DAyfx2x965iDN3Fd2J9DXgXIpUbUa0hBqoG9m7CPjPfJ8qbZIXqlMvB
+Po/WB3DbjGsSmCsDXmjpzQh5YNkvgMmAqpPkmQ3EWeQZJDmEBTPNwq+Qq5Hk3Fc9x5uRbkc9D+7
45EDwb7drWu+9TKY03og5mvf3X5dc+ng1J6SAmH8Y4LkTrf4xbomcgFZE5IGNOrgzcCeU7BHUVQ/
0TPh4NsWaxp/3fecjUATqJbPfRu5+R7CCoztCitj14Pej2c3YwtubRSQ/ckqN5Pr7kQNqNTBa7vn
dQocQbt6C4m/X8mdrIDYB0HvL7ZHz4BlBSo/wcrLCYq+1bame+oW1LwHYWTb+rA5DmtuIMmdH1cN
mKCB8Im+albOJsqgkrkQZGRBA7ABzmnsA94ZXWNHIEj/krCdELTBbzuVxEC5WUQh45K+NU7NX5ME
kMqlbk46v5mLSu7bi4XeYoFRw6/u2TGBpcWDc/YmUSDo5Z4WsugAwsrJrAJWFmVMYVyhZlbSDt+H
sT1cs3kJUQ/X3IzTrnKgDqTh/mjwrZLruI/bqKZvZCh5LVb+mYF0P2rZe1GmyEwBUDNCW/6NNAET
Q13h3hFYPwz3Do+wufpDaqnn1AUPfH2YSvwOND0E4V9RXo7q21F5tGuMWWgPfIdxX1gjsDoHXdBY
84Gk6PTPQf87qoeCHovoWYhu6uqNClYOJ80/RRbDxgAeGYBYLsfPnWx0as1aHhp6Ew+NwEMj8OgQ
pJHzHqQhaPWt4O9ZyuvcTuD5/49gEPxBiAZhqOZ6ZtRx0IyjyeRVTlUz4GePcsCWb/GiSThwcj2D
8TUkHR1AXozx3o5fuMbmKz4wYLjm1myHgFMWfYbowXkWQYDm2O3k4RFI7oybWn4fL332IMAlU6wb
gyCH3FyKNR/BqONSubQ5ZPNuRLZetvckMIXzkWXAzPhPMMEJDq0Cojcj9lUMZOBb2FyF4QQGMqhl
L2HD0F1kEuFpkbDRfBMmuRbrOwe4KBi5AuRUvKIflgdZ0ziMzNZ5JoQgKkR3CsoYqX83gezuVBcD
YfYBjP1HFBe5cqzljVh+2DOrX8dKldQbxOR/QJAX/lFx/VYdI/N+jOEV5UQKSlRfieQTtCrOPyxc
hvAXZR1jf0aeHEdqIMxg92JfZ8AzY7eiwVFIIQkiPQfPXljmBdiCKZiOwagQez8jk2MdOD2oNE9j
pH55GcGbrB7BzNDteB1OqPdj7EsWVOc90HHD606a4ie3zwXoUs+rbX8gsDPGoPnRknsGFhr+S3hi
4M1MhbA5hNRCIkNYcxqmT9e8pA+YsQ9eFdZEMFaBodph4J+AFsBUTcmmTyJvQyuAeuR63wyg4YOf
3UeQnt3H6cQ/HQag5sN+M7B7czVwamksxAb2mXkHY3GdmapzUpe7zsBeU1vYe+oD5MU0iYXY+1+k
vjNsZIFVSmU/ouSLHDRxIqtb0O41XAQ83YJv30AZ5lRIjBB672YcGI2dHimNi/smPPZfxVj7EA6c
gr0b0Bp0JR88FLyjoACmaspuE59jjwnYcwL2fNb5WyfFxdbrFupyDLk5touAfJrEv4LNI/DUGDy2
AprVO/CzW3r6/mKsvA3L/CpnRWhusNx59/Mt1pNZJcUZLRrfgJfcXqbThTlsqn2G+0dg4yAMKPh6
JobIzYFAYGPWNC/qa39TBA8NwCM12FCFTbVTSjADSP4vmGACU3EcKzFO3KlAPYR7xiE2lxLkz7j6
Cuq/ljSp0s5gugL16OQy8yYXGE5uITF38cAYJIFzYVG4rKwPUyOQht9F8t9010dfhqf7EtquF6KP
BHIdwEuuZSi11LKOV8KJzlQgEcj1Wax+oRygsdCO3kQ7Aj+EMQ9q/iY8vaoM24pCPHAujRqueNAw
8GT1L0sPiQoE+ZeYDKbZVIFNFXiiCjO+8zP7FGLYXFrOrRUYsH/HgE3LFEGPTvriObMg8wWsGLcJ
ZpUxj1+ty5lp7pjklsW7kraWZuU3zyQJf1peC/QQLG/Es9dibI0sOAMKoLU92LNxKVHe5Zq5wHTk
rOgSjHps+bsCg9mNJNHrSP0DEEuPq8T9HUkOfhPVBrBbceMAkh9CZm5jKgA4vtyOKhAl32HGP5rE
O4Qwn+VytLAltNioQZjHfePN9EhyNrh6zAKoQiQprQFYN1B4NqzbSEMt+sODcjWt6KNlu7keRBS7
MdUH3aYz9nzgnU5KWagH72RzuDuBbmLtFKis4rHwXQ6cRYJKy7+I5mjX1Rxm4HdCfAqYY7Hm97o+
U5uzcvpc2hWYCbsRJAuI/gjhSpSj3RUZwshhwJ1zsFARrrl1nlyK5xycW9Vx4xsguR3CI8sUQE8+
Dfm1JHzIpcSpC2UO2Jh9mp/pu70VQNN30RRbRBmk42MrANgKfaz8B9jB+YMoC+xWM7QvcBuag8ia
8rqn0Ay3oOYK/PzgeW/3F/BjWtkXlflTAcvuGCdhOpvIA4ZTuvqQQmYeoxm570YhN8M8Nlgh8dqs
asH+dRBdx1MDN9KoHI9fbKCV2ccYSc7g4WFQOR2/sLxTD4bj7zCYbCj7kRqY9LvzCJB7Xb8mAkH+
eTYO7EMcHklg+31AKhmi/4nKnQg5aBXlCJB+cHqgE3DjL3aMMQRLAedCbqvSUd48Az+8sfxuOYJA
j8V6J5EXu9p6UKlfymQ+U6aJKG7yfOlRMuaJ8uSiiDRAB1kS5b2ZJ9r3Z6CQanPJESWh4hZ6sfd1
ghLFADvqCPOk0IlRfIFYoJ0VRlx6HlK5DopbUvkzbHoGQzk0Bj5Uck0AI+fhFSpWJ2PeaPEoC9Y/
DjVHdzP79Vms+T7Wu4dAB+YdU0dl6Fse+xTw7fLagNB8POXOe3YGOJNt/J7GNxEktxKGR5fcU73T
MNyI1eNBILQxM83P8OysgYYBRLP0N2UTsLa7uKmPmrcg/t7ubMM8VCYFu4ejWDxuKBOFLU92xapA
xl5g3gPmZWgez1mX3iiKk98C6iNycy8DnJc6Tm4rYFMX/WkVafyx1wHTvl1uJlDJJ1nTcP1IDGwc
c2La1x8h+X0gL8FTiL0aGwdfT5QrIqNlJ8L8Dma8O9nSsx/DBKKsJ4/B6/o1rUCQfRHhYCwDi8aC
CIhcjOTfLudppeGXN6TUd5C+CUsB53/bxu8KxM2P8kR4EwGdUN3JePF7QR5Go/2R+mVU7QyVWfel
HmQ6u7078Di+nAzLO9CJzyO1O6DWc3MvICiOQRSupNw4zuGlBcDkFjL/lNIoSsNT0Nb/QfL1mJpL
WOmNL3cc6ApoUpztKcKQCx3EUYHcBqxown4teLoKj4TOuEg9x71mAmc4WvNHpbQwQM7dTBTAysUZ
k6bDde1f0fC+ViaUiJ6N1W92N4mA5XyMurZVnLM+KFQloxB7x5Gbo8pxGY2pJA/RjK4ox7zNRS4+
VQ7EmnciXOWQ5HHNz3bsqY7F+zkXK8LuHruZZnhMOUFe9reIyUn9M4g2D2Pymd4xulzHEQemftZ5
GIn/i77nS/PNYP8LiQrA+IUhYd1httEMpqzj8lXfHVRLKi46YjKw3mrS2pPdUKKBuPFhRlqfxxuG
Kb8QfwJZDiOxi8JM+yDTDqBpDaQKoXFqgWuq38+ZcxO19EL2blxHPch4NATjw0jeBWegK8nMY0DF
gU8g4X2ofqm0fveecFwPnL7+5NgziL+yjOlL+4/R8DTwX4Nkj2Aa+7vrnYhZMT+deU2j27DmyMLx
D5X0hwzHH2Bz9a0YrbMwKZCRe2cjHNRz/WFMfgCeoOPCa0+a4qd39In1lbjQimtkiX7OHQ/OyegY
1o/eTLWzi6SOZJfht56g2v5iH8Nxp4ZglXFAmc0JHxj9L9reH3YNE5MAf4jX/jHW6eYOnFkBzhym
zFnEwQqG2h9jaMa5bZpDzi9pcrDe32G9D5cAzXwYa/8xaq9iJig2gQdpG1a03DMmwvfjp4eQ109n
OHPyJh6BdoXCQd0PTvRr5KaK0TGGkzcwk0NagfHMgfPZygonqjnUVRcw2ma0PY5Kq5zqyRQS2w05
etWPo9WLes6dfRmxN5AHX8WLT0OSy13/xW3YrJPGZkH8V+NFPy3n2HHTdzLU/neaZhtJyJ318l8P
5rpZCHo3Q+YbTz+Us/+bt9CMFeBY4O+BA3ApPO8HfrHzwQlw79jN1MNjiHInWr30B0SNPyhO9vU3
aQEqzOOTgczsQeY9DBJ2FXgByf4BtVdD8ABCgLUHYuRVIG9D5SCsB1HrEwxMX4ioxXrQGu/s/wpt
eZBIu6E+x13+FfTrWHMfIopmawnyoyB4K6l5pTtea6/glc+eBjYl8eE34457is4G55XARjBnIfpL
SP+BzP81NRsgehyN4HQ8XVVWb4awT/1D7Dn9hb7x3z/uomBR3nETDQKbCWxURH4sfvJBUu8UEu9k
hC0uCpW7fay9Iiq6HfGOAHVrEmUb2HNmP1B4vOa8C/MdfxYcyFOv0FtlHYG+rDtUHmON3efxuzP2
fsM0uOTKx+jqXgCTwHtUe5NJdhY4J6PfY/3oz7vck4uBj8/ZmWocJ6lsmd+hHSjE1VeTDF4PavoA
6hroN5nLbx2ndT6MivMM7NFyic6q0JIXs3HwVkRG+57bO0TpJIkoZagThdHWWkz+W1RgulzU2eD8
Fsgm4LTSEOmI0o7O2Olr4oHNvsc+028pz1tZ3Fn8VgSNoN+l1fDPZ7JyLr51RlaY/JTd279PVeNy
fiY8eMp35/KxoP5r0Oj6btzdg8HmB1kx80WsgYmai9DNl6SSeLB7DGOFuK4Hb2XDwLfx8q7f+oDG
e9tj9a8ecwz86g5OBL43Dyr+RVVPWTyInqtzjKPxLYwmN5F44BJdzy0Xv68UK+RpcaRhVhEFP7mB
IDkKWF/qYt2M8S6cSqPAgGT3ofmJ5H4da6AVOm4y1IbhGHZr3w/Zoajc5Kxpmbv3tIgUdfRBI08g
2bvZ1N7AYyk8qU78u/t6Tq4JSL4RP/mWe9FD0d9OwMBot+uxD8PJ1ax+9i1MpY7fPAY8AWzwHEf1
tT8KA9eUIPMU0vAEpvyYhnaP/nYsZqtgpYIGX+nrn8liWuZLPDoCjw+7MO5CL40QBVqQx66Y+n9g
sg0ldAKFZ8K/r1BZsXY3H+DhBVCxbqkweu4O2e5Z/7hbGHsJkiVIxpxiCqd0xxU5XxqWyzG8k+H2
S6m2P4qoCyV20uQ6qLK0CO31BMmp5OlBkH0fQR0gLGV8HDrO8UdROZ4wfx9Rfns/3sswVYLJb8bL
z0DkINBv4OcZUe7O41ucd0D1cZzomga9HuECrF7HSHIoA8lV2B4vcaff1q6jEp/K6tbJRBSnI4vi
G2iOwIZheGTIvaHkkSF4dBCmor8g6FFHxH6diRAeGnbZSo8MOY4bCRgPJNgbZC/QaWAa1Wl8PR3f
ywg8CIx7i4o3X2KPgTCGJy3ciyuPAH7zfyIyAUzj22laXsDMwKEf/1BI4HMvcMGsls4GLlsqhBYv
1h9fou/bCmyJ/hQ/+yZipxZUC6wBPynO5szuHe71MvEgDKUuwXcqhCw4mGE9hESrpNJG/afQ9DeM
26dJM9jiu0nvrHrbgwOmYVWRZ9EG1o86S36s5SI3E5UDaQevwEuGsDaG4FmUXxPEG/HEWenagrQY
ijdK/zl2M+w+dRqTO9fY7sUm3FjdHRMejmQrqKaWJFxPK7udkdQFWhtNx/E6r+1JPUgi8LKujm4U
8mB/8oGHXOZRca2aHIjwoNvEhdM9167KZI1HHA6WHbXAgJ0mpGtXiUBmIbazlskDabrk6r41CSEd
HUSKqEJshKMmGhlZNlSFtqv+KuBFwHrgNli6tb54P+czSzxzbRQ8++XuiBagxaqyKt3kXmUdlXwd
ubpECoJiIYo6SwjclEkVRh8AHqBMuQkpFcN5czbneOGn57SdF5az6Cbgu6BQSd2Go1BBel9PkwHD
wCrrQNE3n8AT4VlMGafyWIHQ/oDd0gf7+pcLbPYLtQSK0wJTc7reObDYe22hSZpjKwi4jNueNTRM
TPWlDvysKNtNiwdntB0O1lwWD5TtabtP7fwdHlSK9E47z1WncerFQi4bgMlxmDHMdfLLMLH5U5fA
QScB+TweGZrVnoFKe+7x3xcg7QIHwpep5OA64rLaU0ufOeDOLp2Oqt89oGnuwujP53K1Ijq2c9/B
tUNoGZy7AllgWKCWOv1uNuByY3i89pGup0Igb53vMq1m1XXJxlDxFrbAXyC0DM6dSYp7D5QRmBx0
7wztfVlFh3Lv/aiMl24owyaq9up59QPtxONl7gvUXmC0DM6dSUaLs+o1l9OazqcnqsH65/UnpGTn
ICHz6sYd5mqzeYNuLyRaBufOJE+dA7y1QHQGKA72fQNhyH3VGdT+O/FW8kmV7nGMFzAtIXy5TMv0
/NLyPyxYpl2WlsG5TLssLYNzmXZZWgbnMu2ytAzOZdplaRmcy7TL0jI4l2mXpf8PIL46jiMrLXkA
AAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDktMTBUMTY6MzY6MjMrMDA6MDCPogVhAAAAJXRFWHRk
YXRlOm1vZGlmeQAyMDIwLTA5LTEwVDE2OjM2OjIzKzAwOjAw/v+93QAAAABJRU5ErkJggg==" />
</svg>
);

export default Logo;
