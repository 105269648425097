import React, {Fragment} from 'react';
import {Pagination, List, Datagrid, TextField, DateField, SelectInput, downloadCSV, EditButton, Filter, TextInput, useTranslate, ImageField } from 'react-admin';
import {NoticeSendButton} from '../../control/button/index';
import {USER_TYPE_CLIENT, USER_TYPE_CLIENT_PRO} from '../../util/constant'
import {FormattedDateField, AvatarField, VerifyField, GenderField, GenderFilterField, LockField, ValidateField} from '../../control/field/index'
import jsonExport from 'jsonexport/dist'
const { getCode, getName } = require('country-list');

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
        <NoticeSendButton {...props} toClient={true}/>
    </Fragment>
);


const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.client.list')}</span>;
};

// const AccountField = ({ record = {}, size }) => (
//     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         <AvatarField record={record} size={size} />
//         &nbsp;{record.email}
//     </div>
// );

// var genders = [];
// genders.push({id: GENDER_MALE, name: 'Monsieur'});
// genders.push({id: GENDER_FEMALE, name: "Madame"});

// const GenderFilterField = ({ record }) => {
//     return <span>{record.id === GENDER_MALE ? 'Homme' : 'Femme'}</span>;
// };

// const GenderField = ({ record = {}, label}) => {
//     return <div>
//         <span class>{record.gender == GENDER_MALE? 'Homme' : 'Femme'}</span>
//         {/* <span class>{record[source]}</span> */}
//     </div>;
// }

const exporter = clients => {
    const dataForExport = clients.map(client => {
        const { id, user_type, gender, activity_type, company_status, postal_code, vat_no, country_name, nationality_name, full_name, user_number, user_id, user_name, phone, status, activated, locked, validated, activation_code, activated_time, activation_expired_date, company_name, company_address, company_number, address, city, logo_url, siren_number, tva_intracom_number, iban, manager, birth_date, country, nationality, company_id, external_id, photo_url, password, settings, points, reg_code, fb_account_id, fb_access_token, contract_date, contract_end_date, update_time, deleted, delete_time, mode, app, ...exportData } = client; // omit backlinks and author
        return exportData;
    });
    jsonExport(dataForExport, {
        headers: ['last_name', 'first_name', 'mobile', 'email', 'create_time' 
        ], // order fields in the export
        rename: ['NOM', 'PRENOM', 'MOBILE', "EMAIL", "DATE D'INSCRIPTION"
        ],
        verticalOutput: false,
        booleanTrueString: 'Oui',
        booleanFalseString: 'Non',
        rowDelimiter: "\t",
        // forceDelimiter: true,
    }, (err, csv) => {
        downloadCSV(csv, 'clients'); // download as 'posts.csv` file
    });
};


const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn resettable/>
        <TextInput label="Mobile" source="mobile" alwaysOn resettable/>
        {/* <SelectInput label="Civilité" source="gender" alwaysOn choices={genders} 
        allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}
        <TextInput label="Nom" source="last_name" resettable/>
        <TextInput label="Prénom" source="first_name" resettable/>
    </Filter>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;

const ListClient = ({permissions, ...props}) => {
    const translate = useTranslate();
    return (
    <List title={<Title/>} {...props} perPage={50} pagination={<ListPagination/>} 
        filters = {<ListFilter permissions={permissions} />} 
        resource="client" filter={{user_type: USER_TYPE_CLIENT}}
        bulkActionButtons={<BulkActionButtons />} exporter={exporter}>
        <Datagrid rowClick="show">
            <AvatarField source="photo_url" label="" />
            <TextField source="id" label="ID"/>
            {/* <GenderField source="gender" label={translate('civility')}/> */}
            <TextField source="email" label="Email"/>
            <TextField source="last_name" label="Nom"/>
            <TextField source="first_name" label="Prénom"/>
            <TextField source="mobile" label="Mobile"/>
            <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/>
            {/* <VerifyField source="activated" label="Mobile vérifié?"/> */}
            <TextField source="address" label="Adresse postale"/>
            <TextField source="country" label="Pays"/>
            {/* <TextField source="nationality" label="Nationalité"/> */}
            <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/>
            <ValidateField source="locked" label='Verrouillé'/>
            
            {/* <EditButton/> */}
        </Datagrid>
    </List>
    
)};

export default ListClient;

