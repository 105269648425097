import React from 'react';
import {
    SimpleFormIterator, Edit, NumberInput, TextInput, number, TextField, SimpleForm, required, TabbedForm,
    FormTab, useTranslate
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import Button from '@material-ui/core/Button';
import { ShowButton, SaveButton, Toolbar, TopToolbar } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const EditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        {/* <ShowButton basePath='/view-my-profile' record={data} /> */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </TopToolbar>
);

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Enregistrer"
            redirect="/config"
            submitOnEnter={true}
        />

    </Toolbar>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('menu.settings')}</span>;
};

const validateNotSmallerThanOne = (value, allValues) => {
    if (value < 1) {
        return 'Doit ne pas être moins que 1';
    }
    return [];
};

const validate90 = (value, allValues) => {
    if (value < 90) {
        return 'Doit ne pas être moins que 90';
    }
    return [];
};


const validatePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validateNotPositive = (value, allValues) => {
    if (value < 0) {
        return 'Doit être positif';
    }
    return [];
};

// const validateGreaterThanZero = (value, allValues) => {
//     if (value < 1) {
//         return 'Doit être au moins 1';
//     }
//     return [];
// };

const validatePositiveNumber = [required(), number(), validatePositive];
const validateNotNegativeNumber = [required(), number(), validateNotPositive];
const validateNotSmallerThanOneNumber = [required(), number(), validateNotSmallerThanOne];
const validateNighty = [required(), number(), validate90];
// const validateMaxCount = [required(), number(), validateGreaterThanZero];

// const EditConfig = ({ staticContext, ...props }) => {
const EditConfig = props => {
    return (
        <Edit
            actions={<EditActions />}
            id='noid'
            resource="config"
            basePath="/config"
            redirect="false"
            title={<Title />}
            {...props}
        >

            {/* <SimpleForm redirect="list">
                
            <TextInput source="currency_code" label="Code de monnaie"/>
            

        </SimpleForm> */}
            <TabbedForm toolbar={<EditToolbar />}>
                <FormTab label="Généraux">
                    {/* <TextInput source="currency_code" label="Code de monnaie"/>
                <TextInput source="currency_symbol" label="Symbole de monnaie"/> */}
                    {/* <TextInput source="unit_time" label="Symbole de monnaie"/> */}
                    <NumberInput source="commission" label="Commission Veebya (%)" validate={validateNotNegativeNumber} />
                    <NumberInput source="vat_2" label="TVA Auto-entreprise(%)" validate={validateNotNegativeNumber} />
                    <NumberInput source="vat_6" label="TVA Autre(%)" validate={validateNotNegativeNumber} />
                    <NumberInput source="doc_validation_warning_before" label="Informé pour l'expiration de documents avant (jours)"
                        initialValue={1} style={{ 'width': '600px' }}
                        validate={validateNotSmallerThanOneNumber} />
                    {/* <TextInput source="invoice_monthly_content" label="Contenu de la facture mensuelle" fullWidth validate={required()}/> */}
                    <RichTextInput source="invoice_monthly_content" label="Contenu de la facture mensuelle" fullWidth validate={required()} />
                </FormTab>
                <FormTab label="Commande">

                    {/* <TextInput fullWidth source="manual_dispatch_postal_codes" style={{'width':'50%'}} helperText="Exemple: 13001-13999, 14000, 14007. Tandis que 13001-13999 pour les codes postaux de 13001 à 13000. 14000 et 14007 sont des valuers individuelles. Séparées par des virgules"
                    label="Zones (codes postaux) pour le dispatching manuel"/> */}
                    <NumberInput source="timeout_order_wait_for_accept" label="Délai d'acceptation (secondes)" validate={validatePositiveNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="order_free_wait_min" label="Temps d'attente gratuit classique(minutes)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                    <NumberInput source="order_free_wait_min_airport" label="Temps d'attente gratuit aéroport(minutes)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />


                    {/* <Typography variant="h6" gutterBottom>Immédiate</Typography> */}
                    <NumberInput source="pick_up_radius" label="Rayon autour point de départ(km)" validate={required()} style={{ 'width': '600px' }} />
                    {/* <Typography variant="h6" gutterBottom>Réservation à l'avance</Typography> */}
                    <NumberInput source="soonest_reservation_time" label="Heure de réservation au plus tôt (minutes)" validate={validateNighty} style={{ 'width': '600px' }} />
                    <NumberInput source="latest_reservation_time" label="Heure de réservation au plus tard (minutes)" validate={validateNotNegativeNumber} style={{ 'width': '600px' }} />
                </FormTab>

                {/* <FormTab label="Contenu de la facture mensuelle">
                <TextInput source="invoice_monthly_content" label="Valeur du code de réduction pour le nouveau compte client" fullWidth validate={required()}/>
                
            </FormTab> */}
                {/* <FormTab label="Comptes">
                <TextInput source="system.mail.sender" label="Expéditeur" validate={required()}/>
                <TextInput source="system.mail.receiver" label="Destinataire" validate={required()}/>
            </FormTab> */}


                <FormTab label="Coordonnées Veebya">
                    <TextInput source="company_name" label="Nom" validate={required()} fullWidth />
                    <TextInput source="company_address_line1" label="Adresse ligne 1" validate={required()} fullWidth multiline />
                    <TextInput source="company_address_line2" label="Adresse ligne 2" validate={required()} fullWidth multiline />
                    <TextInput source="company_email" label="Email" />
                    <TextInput source="company_phone" label="Téléphone" />
                    <TextInput source="contact_email" label="Email de contact" />
                    <TextInput source="register_email" label="Email pour l'inscription" />
                    <TextInput source="iban" label="IBAN Veebya" style={{ 'width': '600px' }} validate={required()} />
                </FormTab>


            </TabbedForm>


        </Edit>
    );
};

export default EditConfig;
