
import React, {Link} from 'react';
import { ReferenceField, SimpleShowLayout,TextField, Show, useTranslate } from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {ClientField, OrderStatusField, ActivityTypeField,OrderOptionsField, FullNameClientField, FullNameDriverField, FormattedDateField, OrderTypeField, DistanceField, DurationField} from '../../control/field/index'
import { OrderStatus } from '../../util/constant';
import { AssignAllButton, AssignButton } from '../../control/button';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const AssignOrderButton = ({ record = {}, label}) => {
    const status = parseInt(record.status);
    
    var showAssignButton = false;
        
        if(status & OrderStatus.CANCELLED){
            const cancelType = parseInt(record.cancel_type);
            if(cancelType >= 20 && cancelType < 30){
                showAssignButton = true;
            }
            
        }
        else
            if(status & OrderStatus.DENIED || status & OrderStatus.CREATED) {
                showAssignButton = true;
            }
            // else
            // if((status & OrderStatus.ASSIGNED) > 0) {
            //     showAssignButton = true;
            // }
                
        if(showAssignButton)
            return <div>
            <AssignButton record={record}/>
        </div>;
    
        return <div/>;
    }
    
    const AssignAllOrderButton = ({ record = {}, label}) => {
        const status = parseInt(record.status);
        
        var showAssignButton = false;
            
            if(status & OrderStatus.CANCELLED){
                const cancelType = parseInt(record.cancel_type);
                if(cancelType >= 20 && cancelType < 30){
                    showAssignButton = true;
                }
                
            }
            else
                if(status & OrderStatus.DENIED || status & OrderStatus.CREATED) {
                    showAssignButton = true;
                }
                // else
                //     if((status & OrderStatus.ASSIGNED) > 0) {
                //         showAssignButton = true;
                //     }
                    
            if(showAssignButton)
                return <div>
                <AssignAllButton record={record}/>
            </div>;
        
            return <div/>;
        }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_running.show')}: #{record ? `${record.order_number}` : ''}</span>;
    
};



// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderRunning = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
            <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
            <AssignOrderButton className={classes.inlineBlock}/>
            <AssignAllOrderButton className={classes.inlineBlock}/>
            <div/>
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            
            <div/>
            <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
            <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
            <div/>
            <ActivityTypeField label= "Type d'activité demandé" source="activity_type" className={classes.inlineBlock}/>
            <ActivityTypeField label= "Type d'activité accepté" source="accepted_activity_type" className={classes.inlineBlock}/>
            
            <div/>
            {/* <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
            <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/> */}
            <FullNameClientField label="Client"/>
            <div/>
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>
            <div/>
                <DistanceField label="Distance" className={classes.inlineBlock}/>
                <DurationField label="Durée" className={classes.inlineBlock}/>
                <div/>
                <TextField source="luggage_count" label = "Nombre de bagages" className={classes.inlineBlock}/>
            <TextField source="passenger_count" label = "Nombre de passagers" className={classes.inlineBlock}/>
            <div/>
            <OrderOptionsField label="Options"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandé"/>
            <div/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/> */}
            <FullNameDriverField label="Chauffeur"/>
            <div/>
            {/* <ReferenceField label="Client" source="client_id" reference="client" link="show">
                <TextField source="email" />
            </ReferenceField>

            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="show">
                <TextField source="email" />
            </ReferenceField> */}
            <Divider/>
                <TextField source="original_total" label="Montant original (€)"/>
                <TextField source="promo_code" label="Code de remise" className={classes.inlineBlock}/>

                <TextField source="discount_amount" label="Réduction(€)" className={classes.inlineBlock}/>
                <div/>
                
                <TextField source="wait_amount" label="Frais d'attente(€)" className={classes.inlineBlock}/>

                <div/>
                
                <TextField source="driver_amount" label="Commission chauffeur(€)" className={classes.inlineBlock}/>
                
                <TextField source="tips_amount" label="Pourboire au chauffeur(€)"className={classes.inlineBlock}/>
                <div/>
                <TextField source="commission_amount" label="Commission Veebya(€)" className={classes.inlineBlock}/>
                <TextField source="total" label="Total(€)" className={classes.inlineBlock}/>
            
            <div/>
            <Divider/>
            <br/>
            <Typography variant="body2" color="primary">Déroulement</Typography>
            <div/>
            <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/>
            <div/>
            <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/>
            <FormattedDateField source="onboard_time" format="format_date_time" label="Passager à bord" className={classes.inlineBlock}/>
            {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/> */}
            <div/>
            <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée"/>
            
        </SimpleShowLayout>
    </Show>
)};


export default ShowOrderRunning;