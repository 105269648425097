import React, {Fragment} from 'react';
import { List, Datagrid, Pagination, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {locationDict, locationTypes} from '../../util/variant'
import {ValidateField, LockField, AvatarField} from '../../control/field/index'
import {PdfViewButton, DownloadButton} from '../../control/button/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.invoicing.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const statusList = [
    {id: 0, name: 'Non-vérrouillé'},
    {id: 1, name: 'Vérrouillé'}
];

const DownloadFileButton  =  ({ source, record = {}, label}) => {
    console.log("Invoice file path = " + record.file_path);
    if(record.file_path === null || record.file_path === "") return <div/>;
    return <DownloadButton fileUrl={record.file_path} fileName={record.file_name}/>;
    // return <DownloadButton onClick={(e) => alert("Fichier facture n'est pas encore disponible")} fileName={record.file_name}/>;
}

DownloadFileButton.defaultProps = {
    addLabel: true,
};


const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Année" source="year" alwaysOn resettable/>
        <TextInput label="Mois" source="month" alwaysOn resettable/>
    </Filter>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[12, 24, 36]} {...props} />;

const ListInvoice = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} perPage={12} pagination={<ListPagination/>} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="">
            <TextField label="Année" source="year"/>
            <TextField label="Mois" source="month"/>
            <DownloadFileButton label="Fichier .zip"/>
        </Datagrid>
    </List>
);

export default ListInvoice;

