import React, {Fragment} from 'react';
import { ReferenceField, List, Datagrid, TextField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {weekDays} from '../../util/constant'
import {OrderTypeField, FormattedDateField, ActivityTypeField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} content="Voulez-vous supprimer cette majoration ?"/>
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price_time.list')}</span>;
};

const DaysTextField = ({ source, record = {}, label}) => {
    if(record === 'undefined' || record.every_day === 'undefined'
    || typeof(record.every_day)=== 'undefined') return <span></span>;
    
    const everyDay = record.every_day[0];
    if(everyDay === '1')
    return <span class="MuiTypography-root MuiTypography-body2">Tous les jours</span>;
    const days = record.days;
    // const days = day.split(',');
    const length = days.length;
    // return <span class="MuiTypography-root MuiTypography-body2">{length}</span>;
    if(length === 1)
        return <span class="MuiTypography-root MuiTypography-body2">{weekDays[days[0] - 1]}</span>;

    return days.map((day, index) => {
        if(index < length - 1)
            return <span class="MuiTypography-root MuiTypography-body2">{weekDays[day - 1]}, </span>;
        else
            return <span class="MuiTypography-root MuiTypography-body2">{weekDays[day - 1]}</span>;
    })    
}
    
DaysTextField.defaultProps = {
    addLabel: true,
};

const UnitTypeTextField = ({ source, record = {}, label}) => {
    const unitType = record.unit_type;
    return <span class="MuiTypography-root MuiTypography-body2">{unitType == '0' ? '€': '%'}</span>;
}
    
UnitTypeTextField.defaultProps = {
    addLabel: true,
};

const ValueTextField = ({ source, record = {}, label}) => {
    const markupUnit = record.markup_unit;
    const markup = record.markup;
    if(markup > 0)
        return <span class="MuiTypography-root MuiTypography-body2">+{markup}{markupUnit == '0' ? '€': '%'}</span>;
    return <span class="MuiTypography-root MuiTypography-body2">{markup}{markupUnit == '0' ? '€': '%'}</span>;
}
    
ValueTextField.defaultProps = {
    addLabel: true,
};


const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        {/* <TextInput label="Name" source="name" alwaysOn resettable/>
        <TextInput label="Address" source="address" alwaysOn resettable/>
        <SelectInput label="Type" source="type" alwaysOn choices={locationTypes}
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
        /> */}
        
    </Filter>
);

const ListPriceTime = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            {/* <TextField source="vehicle_type_name" label = "Type de véhicule"/> */}
            <ActivityTypeField label="Type d'activité" source="activity_type"/>
            <OrderTypeField source="order_type" label="Type de commande"/>
            <DaysTextField label="Dates"/>
            {/* <TextField source="days" label="Dates"/> */}
            <TextField source="from_time" label="Heure de début"/>
            <TextField source="to_time" label="Heure de fin"/>
            {/* <FormattedDateField source="from_time" label="Heure de début" format="HH:mm" locales="fr-FR"/>
            <FormattedDateField source="to_time" label="Heure de fin" format="HH:mm" locales="fr-FR"/> */}
            <ValueTextField source="markup" label="Majoration"/>
            
        </Datagrid>
    </List>
);

export default ListPriceTime;

